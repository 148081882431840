<template>

<app-content :loading="is.loading && !is.initialised">

	<app-content-head title="Categories" subtitle="Manage featured or sponsored categories in the library." :readonly="$authorised('org/library/access', 'read')">

		<app-content-head-actions-item text="Create" icon="create" v-on:click="onCreateClick" />
		<app-content-head-actions-item text="Delete" icon="delete" :danger="true" :disabled="!selected.length" v-on:click="onDeleteClick" />

	</app-content-head>

	<app-content-filters>

		<app-content-filters-search v-model="filter.search" />

	</app-content-filters>

	<app-content-body :loading="is.loading && is.initialised" :is-empty="!collection.length" placeholder="Sorry, no categories found.">

		<app-table>

			<app-table-head :layout="layout">

				<app-table-column text="Category" />
				<app-table-column text="Title" />
				<app-table-column text="Enabled" align="center" />
				<app-table-column text="Pinned" align="center" />
				<app-table-column />

			</app-table-head>

			<app-table-body>

				<app-table-row v-on:click="onEditClick(item.id)" :layout="layout" v-for="item in collection" :key="item.id">

					<app-table-cell-text :text="item.bgg_category.name" />
					<app-table-cell-text :text="item.name" />
					<app-table-cell-toggle :value="item.enabled" />
					<app-table-cell-toggle :value="item.pinned" />
					<app-table-cell-select :active="isSelected(item.id)" v-on:click="onSelectToggle(item.id)" />

				</app-table-row>

			</app-table-body>

			<app-table-foot :pagination="pagination" :total="total" />

		</app-table>

	</app-content-body>

</app-content>

</template>

<script>

import mixCollection from '@/mixin/collection'

export default {

	mixins: [mixCollection],

	data: function() {

		return {
			layout: '200px auto 100px 100px 24px',
			endpoint: 'organisation/library/categories',
			live: 'organisation/library/categories'
		}

	}

}

</script>

<style scoped>

</style>